import React from "react";
import { formatPrice } from "../../utils/number-utils";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./vehicle-header.module.scss";
import { DynamicIcon } from "../../utils/icon-utils";

const ICON_SIZE = 60;

/**
 *
 * Reusable Vehicle Header for raw data provided
 * used for VehicleSelfSelection or when raw vehicle data is provided (rather than vehicleConfiguration)
 *
 * @param {string}          raw_condition - condition of the vehicle
 * @param {string|number}   raw_year - year of the vehicle selected
 * @param {string}          raw_make - make of the vehicle
 * @param {string}          raw_model - model of the vehicle
 * @param {string|number}   raw_price - price provided
 * @param {string}          raw_category - vehicle category
 * @param {string}          raw_icon - vehicle category icon to use
 */
export const RawDataVehicleHeader = ({
   raw_condition,
   raw_year,
   raw_make,
   raw_model,
   raw_price,
   raw_category,
   raw_icon,
}) => {
   const getPriceLabel = () => {
      const label = "Price";
      return `${label}: ${formatPrice(raw_price)}`;
   };

   const getTitleLabel = () => {
      const conditionTitle = raw_condition === "N" ? "NEW" : "USED";
      const model = raw_model === "-" ? "" : ` ${raw_model}`;

      // without make and model
      if (raw_make === "-" && raw_model === "-") return `${conditionTitle}: ${raw_category}`;

      return `${conditionTitle}: ${raw_year} ${raw_make}${model}`;
   };

   return (
      <div className={classnames("border-bottom", styles.container)}>
         <div className={styles.icon}>
            <DynamicIcon
                    iconName={raw_icon}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                />
         </div>

         <div className={styles.info}>
            <p className={`spark-text-14px-bold ${styles.name}`}>{getTitleLabel()}</p>
            <p className="spark-text-secondary spark-text-14px">{getPriceLabel()}</p>
         </div>
      </div>
   );
};

RawDataVehicleHeader.propTypes = {
   raw_condition: PropTypes.string.isRequired,
   raw_year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   raw_make: PropTypes.string,
   raw_model: PropTypes.string,
   raw_price: PropTypes.string,
   raw_category: PropTypes.string.isRequired,
   raw_icon: PropTypes.string.isRequired,
};

export default RawDataVehicleHeader;
